import { FC } from "react";

interface IconProps {
   color?: "gradient" | string;
   size?: number;
}

const SvgGradient: FC<{ id: string }> = ({ id }) => {
   return (
      <defs>
         <radialGradient
            id={id}
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(40.0817 4.26337) rotate(124.912) scale(41.6546 37.0471)'
         >
            <stop stopColor='#AB00D6' />
            <stop offset='1' stopColor='#5585FF' />
         </radialGradient>
      </defs>
   );
};

const generateId = () => {
   const genericId = new Date();
   return "cl" + Date.parse(genericId.toISOString()).toString();
};

export const B24Icon: FC<IconProps> = ({ color, size }) => {
   let genericId;
   if (color === "gradient") {
      genericId = generateId();
   }

   return (
      <svg
         width='47'
         height='14'
         viewBox='0 0 47 14'
         fill={genericId ? `url(#${genericId})` : "white"}
         xmlns='http://www.w3.org/2000/svg'
      >
         <path
            d='M2.66386 11.9336V7.63325H3.74005C4.70363 7.63325 5.49691 7.74088 6.06273 8.06915C6.62856 8.39473 6.97029 8.94095 6.97029 9.81005C6.97029 11.2792 6.06273 11.9336 3.91092 11.9336H2.66386ZM0.0560223 14H3.85209C8.1025 14 9.63415 12.2026 9.63415 9.69973C9.63415 8.01211 8.89746 6.86909 7.59494 6.2147C6.57534 5.67118 5.27226 5.50704 3.68403 5.50704H2.66442V2.2405H8.38766L9.06833 0.224609H0V13.9979H0.0560223V14Z'
            fill={`${color || "white"}`}
         />
         <path
            d='M40.9048 10.6725C37.8454 10.6725 35.4079 8.33102 35.3496 5.33625C35.3496 2.3969 37.8432 0 40.9048 0C43.9664 0 46.4599 2.39475 46.4599 5.33625C46.4599 8.27721 43.9664 10.6725 40.9048 10.6725ZM40.9048 1.30339C38.5961 1.30339 36.7065 3.11855 36.7065 5.33625C36.758 7.60507 38.5961 9.36911 40.9048 9.36911C43.2135 9.36911 45.1031 7.5534 45.1031 5.33625C45.1031 3.11855 43.2135 1.30339 40.9048 1.30339Z'
            fill={`${color || "white"}`}
         />
         <path d='M40.2007 2.60645H41.2836V6.11569H40.2007V2.60645Z' fill={`${color || "white"}`} />
         <path
            d='M43.8564 5.07535V6.11559H40.2032V5.07535H43.8564ZM12.3125 14H22.2884V11.877H15.7136C16.6212 8.39194 22.1741 7.63047 22.1741 3.76551C22.1741 1.69634 20.7002 0.172852 17.5825 0.172852C15.6548 0.172852 13.9545 0.716378 12.8223 1.2626L13.503 3.22144C14.5231 2.73173 15.7136 2.29583 17.187 2.29583C18.3215 2.29583 19.3976 2.78555 19.3976 4.03727C19.3976 6.86845 12.8783 7.0864 12.3125 14ZM28.9735 10.6252V14H31.5231V10.6252H33.6772V8.55608H31.5231V0.172852H29.6514L22.5668 8.99197V10.6252H28.9735ZM25.4604 8.6664L29.0878 3.98346C29.0878 4.36554 28.9758 5.56291 28.9758 6.6505V8.60935H27.2749C26.7651 8.60935 25.8016 8.6637 25.4626 8.6637L25.4604 8.6664Z'
            fill={`${color || "white"}`}
         />

         {color === "gradient" && <SvgGradient id={genericId || "InputIcon"} />}
      </svg>
   );
};

export const VkIcon: FC<IconProps> = ({ color, size }) => {
   let genericId;
   if (color === "gradient") {
      genericId = generateId();
   }

   return (
      <svg
         width='24'
         height='14'
         viewBox='0 0 24 14'
         fill={genericId ? `url(#${genericId})` : "white"}
         xmlns='http://www.w3.org/2000/svg'
      >
         <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M22.8472 0.94184C23.0078 0.399305 22.8472 0 22.0747 0H19.5182C18.8672 0 18.5677 0.342882 18.4071 0.724826C18.4071 0.724826 17.105 3.89757 15.2648 5.95486C14.6701 6.54948 14.3967 6.74045 14.0712 6.74045C13.9106 6.74045 13.6632 6.54948 13.6632 6.00694V0.94184C13.6632 0.290798 13.4809 0 12.9427 0H8.92361C8.51562 0 8.27257 0.303819 8.27257 0.585938C8.27257 1.20226 9.19271 1.34549 9.28819 3.0816V6.84896C9.28819 7.67361 9.14062 7.82552 8.8151 7.82552C7.94705 7.82552 5.83767 4.63976 4.58767 0.993924C4.33594 0.286458 4.08854 0 3.43316 0H0.876736C0.147569 0 0 0.342882 0 0.724826C0 1.40191 0.868056 4.76563 4.0408 9.21007C6.15451 12.2439 9.13194 13.8889 11.8403 13.8889C13.4679 13.8889 13.6675 13.5243 13.6675 12.895C13.6675 9.99566 13.52 9.72222 14.3359 9.72222C14.7135 9.72222 15.3646 9.91319 16.8837 11.3759C18.6198 13.112 18.9062 13.8889 19.8785 13.8889H22.4349C23.1641 13.8889 23.533 13.5243 23.3203 12.8038C22.8342 11.2891 19.5486 8.17274 19.401 7.96441C19.0234 7.4783 19.1319 7.26128 19.401 6.82726C19.4054 6.82292 22.526 2.43056 22.8472 0.94184Z'
            fill={`${color || "white"}`}
         />
         {color === "gradient" && <SvgGradient id={genericId || "InputIcon"} />}
      </svg>
   );
};
