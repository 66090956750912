import { createContext, useContext } from 'react';
import { Store } from '../store';

export const StoreContext = createContext<Store|null>(null);

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with RootStoreProvider'
    );
  }
  return context;
};