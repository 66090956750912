import { FC } from "react";
import styles from "./groupsList.module.css";
import { Button } from "../Button";
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";

interface ListProps {
   items: { id: number; name: string; description: string }[];
}

export const GroupsList: FC<ListProps> = observer(({ items }) => {
   const {
      ppStore: { isLoading, selectB24Channel },
   } = useStore();

   if (!items.length) return <div className={styles.message}>Нет групп для публикаций</div>;

   return (
      <div className={styles.list}>
         {items.map((item) => (
            <div className={styles.item} key={item.name}>
               <div className={styles.title}>
                  <h3>{item.name}</h3>
                  <Button
                     size='small'
                     text='Подключить'
                     to={`/connect/${item.id}`}
                     // onClick={() => selectB24Channel(item.id)}
                     loading={isLoading}
                  />
               </div>
               {item.description && <div className={styles.description}>{item.description}</div>}
            </div>
         ))}
      </div>
   );
});
