import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { Button } from "../components/Button";
import { Link, useNavigate } from "react-router-dom";
import styles from "./css/create-page.module.css";
import { useStore } from "../hooks/useStore";
import { Logo } from "../components/Logo";
import { Info } from "../components/Info";

export const CreateGroupPage: FC = observer(() => {
  const {
    ppStore: { isLoading, b24Groups, createB24Channel },
  } = useStore();

  const [group, setGroup] = useState<number | null>(null);

  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [err, setError] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (!name && !err) setError(true);
    if (name && err) {
      setError(false);
      setMessage("");
    }
  }, [name]);

  useEffect(() => {
    if (group) navigate(`/connect/${group}`);
  }, [group]);

  const submit = async () => {
    if (!err) {
      const createGroup = await createB24Channel({ name, description });
      setGroup(createGroup);
    } else {
      setMessage("Обязательное поле");
      console.error("Обязательное поле");
    }
  };

  return (
    <div className={styles.form}>
      <Logo />
      <Info text='Создайте группу в вашем Bitrix24, куда будут идти публикации из Paraposter' />

      <div className={styles.block}>
        <div className={styles.label}>
          Название группы <div className={styles.required}></div>{" "}
          {message && <div className={styles.message}>{message}</div>}
        </div>
        <div className={styles.borderbox}>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div>Описание группы</div>
        <div className={styles.borderbox}>
          <textarea
            name='description'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          ></textarea>
        </div>
      </div>
      <Button
        text='Создать группу'
        onClick={submit}
        error={err}
        loading={isLoading}
      />
      <div>
        <Link to='/setup'>К выбору группы</Link>
      </div>
    </div>
  );
});
