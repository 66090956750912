import { FC, ReactNode, useEffect, useState } from "react";
import { Spinner } from "../Spinner";
import styles from "./button.module.css";
import { useNavigate } from "react-router-dom";

interface ButtonProps {
   text: string;
   icon?: ReactNode;
   loading?: boolean;
   onClick?: () => void;
   callback?: () => void;
   size?: "small" | "normal" | "micro";
   to?: string;
   error?: boolean;
}

export const Button: FC<ButtonProps> = ({
   text,
   icon,
   onClick,
   callback,
   loading,
   size = "normal",
   to,
   error,
}) => {
   const isButton = typeof onClick === "function";
   const isCallback = typeof callback === "function";

   const navigate = useNavigate();
   const [isLoading, setLoading] = useState<boolean>(false);

   const submit = () => {
      if (to) {
         navigate(to);
      }
      if (isButton) {
         if (error === undefined || error === false) setLoading(true);
         onClick();
      }
   };

   useEffect(() => {
      if (isLoading && !loading) setLoading(false);
   }, [loading]);

   return (
      <button
         className={size === "normal" ? styles.button : styles.button + " " + styles[size]}
         onClick={submit}
         style={!isButton && !to ? { cursor: "default" } : {}}
      >
         {isLoading ? <Spinner color='#fff' size={size} style={{ position: "absolute" }} /> : icon}
         <div className={styles.text} style={isLoading ? { opacity: 0 } : {}}>
            {text}
         </div>
      </button>
   );
};
