import { makeAutoObservable } from "mobx";
import { ParaposterStore } from "./ParaposterStore";

export class Store {
  ppStore = new ParaposterStore();
  loading = false;
  error: string | { title: string; message: string } | null = null;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setError(e: string) {
    this.error = e;
  }
  clearError() {
    this.error = null;
  }

  setLoading(bool: boolean) {
    this.loading = bool;
  }
}
