import { useEffect, useState } from "react";
import "./styles/app.css";
import { Spinner } from "./components/Spinner";
import { useStore } from "./hooks/useStore";
import { observer } from "mobx-react-lite";
import { useRoutes } from "./routes/useRoutes";
import { Error } from "./components/Error";

const App = observer(() => {
  const {
    ppStore: { login, isLogin, chain, isLoading, isGroupSelected },
  } = useStore();

  const [loading, setLoading] = useState<boolean>(true);

  const routes = useRoutes(!!chain, isGroupSelected);

  useEffect(() => {
    if (!isLogin) login();
    else {
      if (!isLoading && loading) setLoading(false);
    }
  }, [isLogin]);

  return (
    <>
      {!loading ? routes : <Spinner />}
      <Error />
    </>
  );
});

export default App;
