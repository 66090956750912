import { Navigate, Route, Routes } from "react-router-dom";
import { SetupPage } from "../pages/SetupPage";
import { ConnectPage } from "../pages/ConnectPage";
import { CreateGroupPage } from "../pages/CreateGroupPage";
import { ChainPage } from "../pages/ChainPage";

export const useRoutes = (inChain: boolean, isSetup: boolean) => {
   // if (inChain) {
   if (isSetup)
      return (
         <Routes>
            <Route path='/chain' element={<ChainPage />} />
            <Route path='*' element={<Navigate to='/chain' />} />
         </Routes>
      );

   return (
      <Routes>
         <Route path='/setup' element={<SetupPage />} />
         <Route path='/create' element={<CreateGroupPage />} />
         <Route path='/connect/:id' element={<ConnectPage />} />
         <Route path='*' element={<Navigate to='/setup' />} />
      </Routes>
   );
   // }

   // return (
   //    <Routes>
   //       <Route path='*' element={<Navigate to='/connect' />} />
   //    </Routes>
   // );
};
