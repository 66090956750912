import { FC } from "react";
import styles from "./progressbar.module.css";

export const Progressbar: FC = () => {
   return (
      <div className={styles.progress}>
         <div className={`${styles.bar} ${styles.animate}`}></div>
      </div>
   );
};
