import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { useStore } from "../hooks/useStore";
import { GroupsList } from "../components/GroupsList";
import { Button } from "../components/Button";
import { Info } from "../components/Info";
import styles from "./css/setup-page.module.css";
import { Logo } from "../components/Logo";
import { Spinner } from "../components/Spinner";
import { Link } from "react-router-dom";

export const SetupPage: FC = observer(() => {
   const { ppStore } = useStore();
   const {
      ppStore: { isLoading, b24Groups, getB24Channels, removeConnection },
   } = useStore();

   useEffect(() => {
      if (!b24Groups) {
         getB24Channels();
      }
   }, [b24Groups]);

   return (
      <div className={styles.page}>
         <Logo />
         {isLoading ? (
            <Spinner />
         ) : (
            b24Groups && (
               <>
                  <Info text='Выберете или создайте группу куда будут идти публикации из Paraposter' />
                  <GroupsList items={b24Groups} />
                  {b24Groups.length ? (
                     <Button text='Создать новую группу' to='/create' />
                  ) : (
                     <Button text='Создать группу' to='/create' />
                  )}
               </>
            )
         )}
         {ppStore.chain && (
            <div style={{ fontSize: "12px", color: "var(--dark-warm)" }}>
               Подключено к аккаунту Paraposter.{" "}
               <a onClick={removeConnection} href='#disconnect'>
                  Отключить
               </a>
            </div>
         )}
      </div>
   );
});
