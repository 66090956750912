import React, { FC } from "react";
import styles from "./node.module.css";
import {
  B24Icon,
  CloseIcon,
  InputIcon,
  NodeIcon,
  OutputIcon,
  VkIcon,
} from "../Icons";
import { Info } from "../Info";
import {
  B24Group,
  Node,
  SocialType,
} from "../../store/interfaces/paraposter.interfaces";
import { Button } from "../Button";
import { Link } from "react-router-dom";

interface NodeProps {
  item: B24Group | Node;
  type?: "input" | "output" | "default";
  text?: string;
  onDelete?: () => void;
  loading?: boolean;
}

const getIcon = (item: B24Group | Node) => {
  if ("social" in item) {
    switch (item.social) {
      case "vk":
        return (
          <Link target='_blank' to={"https://vk.com/club" + item.link}>
            <VkIcon color='gradient' />
          </Link>
        );
      case "b24":
        const link = item.link.split(".");
        return (
          <Link
            target='_blank'
            to={
              "https://" +
              link[0] +
              "." +
              link[1] +
              "." +
              link[2] +
              "/workgroups/group/" +
              link.at(-1)
            }
          >
            <B24Icon color='gradient' />
          </Link>
        );
    }
  }
};

export const ChainNode: FC<NodeProps> = ({
  item,
  type = "default",
  text,
  onDelete,
  loading,
}) => {
  return (
    <div className={styles.nodebox}>
      <div className={styles.node}>
        {type === "default" && <NodeIcon color='gradient' />}
        {type === "input" && <InputIcon color='gradient' />}
        {type === "output" && <OutputIcon color='gradient' />}

        <span>{item?.name}</span>
        {getIcon(item)}
        {onDelete && (
          <Button
            text='Удалить'
            icon={<CloseIcon />}
            size='small'
            onClick={onDelete}
            loading={loading}
          />
        )}
      </div>
      <div className={styles.nodeinfo}>
        {text && <Info text={text} size='small' />}
      </div>
    </div>
  );
};
