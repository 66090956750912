import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
export const CONNECTION_URL = API_URL + "/connector/node/code.listen";
export const STORAGE_TOKEN_NAME = "ppntk";

const $api = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  const auth = config?.headers?.Authorization || "";
  if (auth.toString().split(" ")[0] === "VK") return config;
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    STORAGE_TOKEN_NAME
  )}`;
  return config;
});

/*
$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/auth/refresh`, {
          withCredentials: true,
        });
        localStorage.setItem(STORAGE_TOKEN_NAME, response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) {
        // console.log('No authorize');
      }
    }
    throw error;
  }
);
*/
export default $api;
